<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/staff' }">员工管理</el-breadcrumb-item>
        <el-breadcrumb-item>编辑员工</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入联系方式"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="form.sex" placeholder="请选择性别">
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="0"></el-option>
          </el-select>
        </el-form-item> 
        <div class="special">
          <span class="s-required">*</span>
          <el-form-item label="所属部门" prop="">
            <el-tree
              :data="departmentTreeData"
              node-key="id"
              :props="defaultProps">
              <div class="custom-tree-node" slot-scope="{ node, data }" >
                <span @click.stop="">
                  <el-radio
                  @change="departmentChange"
                  v-model="form.departmentId"
                  :label="data.id" >
                    {{ node.label }}
                  </el-radio>
                </span>
              </div>
            </el-tree>
          </el-form-item>
        </div>
        <el-form-item label="岗位" prop="positionIdList">
          <el-select v-model="form.positionIdList" multiple placeholder="请选择岗位" @change="$forceUpdate()">
            <el-option
              v-for="item in positionFilter"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权限" prop="roleId">
          <el-select v-model="form.roleId" placeholder="请选择权限" @change="selectRole">
            <el-option label="超级管理员" :value="1"></el-option>
            <el-option label="高级管理员" :value="2"></el-option>
            <el-option label="管理员" :value="3"></el-option>
            <el-option label="普通员工" :value="4"></el-option>
          </el-select>
        </el-form-item>

        <div v-if="form.roleId != 4 && form.roleId != null">
          <div class="special">
            <span class="s-required">*</span>
            <el-form-item label="管辖部门">
              <el-collapse v-model="activeDepNames" @change="handleChange">
                <el-collapse-item title="选择管辖部门" name="1">
                  <el-tree
                    ref="menuDepRefs"
                    :data="dataDepTree"
                    show-checkbox
                    node-key="id"
                    default-expand-all
                    :check-strictly="checkDepStr"
                    :default-checked-keys="defaultDepKyes"
                    :props="depMenuProps">
                  </el-tree>
                </el-collapse-item>
              </el-collapse>
            </el-form-item>
          </div>
        </div>

        <el-form-item label="区域" prop="regionId">
          <el-select v-model="form.regionId" multiple placeholder="请选择区域" @change="regionChange">
            <el-option label="无" :value="0" :key="0"></el-option>
            <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="册本" prop="zoneId">
          <div v-if="form.regionId[0]==0">
            <el-select value="无">
              <el-option label="无" :value="0" :key="0"></el-option>
            </el-select>
          </div>
          <div v-else>
            <el-cascader
              @change="zoneChange"
              v-model="form.zoneId"
              :key="keyValue"
              :options="zoneListFilter"
              :props="props"
              placeholder="请选择册本"
              :show-all-levels="false"
              clearable>
            </el-cascader>
          </div>
        </el-form-item>
        
        <div class="special">
          <span class="s-required">*</span>
          <el-form-item label="菜单配置">
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item title="公司菜单配置" name="1">
                <el-tree
                  ref="menuRefs"
                  :data="dataTree"
                  show-checkbox
                  node-key="id"
                  default-expand-all
                  :check-strictly="checkStr"
                  :default-checked-keys="defaultKyes"
                  :props="defaultMenuProps">
                </el-tree>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
        </div>

        <el-form-item label="">
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          <el-button size="small" @click="$router.push('/staff')">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        sex:[{ required: true, message: '必填项', trigger: 'blur' }],
        phone:[{ required: true, message: '必填项', trigger: 'blur' }],
        positionIdList:[{ required: true, message: '必填项', trigger: 'blur' }],
        roleId:[{ required: true, message: '必填项', trigger: 'blur' }],
        // regionId:[{ required: true, message: '必填项', trigger: 'change' }],
        // zoneId:[{ required: true, message: '必填项', trigger: 'change' }],
      },
      positionIdListC: [],
      departmentTreeData: [],
      defaultProps: {
        children: "childTree",
        label: "name",
      },
      positionFilter: [],
      position: [],

      // 区域数据
      regionListAll: [],
      regionList: [],
      regionListC: [],
      // 册本数据
      props: { multiple: true },
      zoneListFilter: [],//过滤后册本数组
      zoneList: [],//原始册本数据数组
      zoneListC: [],//册本Id数组
      
      row: {},
      activeNames: ['1'],
      activeDepNames: ['1'],
      dataTree: [],
      dataDepTree: [],
      defaultMenuProps: {
        children: 'childTree',
        label: 'title'
      },
      depMenuProps: {
        children: 'childTree',
        label: 'name'
      },

      defaultKyes: [],
      defaultDepKyes: [],
      defaultDepKyesCopy: [],
      depInitData: [],
      checkStr: false,
      checkDepStr: false,

      isCreate: false,
      keyValue: 0
    }
  },
  watch: {
    zoneListFilter(newVal) {
      this.keyValue++
    }
  },
  methods: {
    selectRole(e) {
      if(e==4){
        this.defaultDepKyes=[]
      }
    },
    handleChange(val) {},
    loadTree() {
      this.$ajax.post('menuCompany', {
        id: this.row.companyId
      }).then(res=> {
        this.dataTree = res.data
      })
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let getDepCheckedKeys
          let getDepHalfCheckedKeys
          if(this.form.roleId!=4) {
            // 管辖部门
            getDepCheckedKeys = this.$refs.menuDepRefs.getCheckedKeys()
            getDepHalfCheckedKeys = this.$refs.menuDepRefs.getHalfCheckedKeys()
            getDepCheckedKeys = getDepCheckedKeys.concat(getDepHalfCheckedKeys)
            // 管辖部门
            if(getDepCheckedKeys == '') {
              this.$message.error('请选择管辖部门')
              return
            }
          }

          let getCheckedKeys = this.$refs.menuRefs.getCheckedKeys()
          let getHalfCheckedKeys = this.$refs.menuRefs.getHalfCheckedKeys()
          getCheckedKeys = getCheckedKeys.concat(getHalfCheckedKeys)
          if(getCheckedKeys == '') {
            this.$message.error('请选择权限配置')
            return
          }

          let addResponsibleDepartmentIds = []
          let deleteResponsibleDepartmentIds = []
          // 新增管辖部门权限id
          if(this.form.roleId!=4) {
            getDepCheckedKeys.forEach(item => {
              let adds = this.defaultDepKyes.find(v=>{
                return v == item
              })
              if(!adds) {
                addResponsibleDepartmentIds.push(item)
              }
            })
            // 删除管辖部门权限id
            if(this.depInitData) {
              this.depInitData.forEach(item => {
                let del = getDepCheckedKeys.includes(item.departmentId)
                if(!del) {
                  deleteResponsibleDepartmentIds.push(item.responsibleDepartmentId)
                }
              })
            }
          }else {
            deleteResponsibleDepartmentIds = this.defaultDepKyesCopy
          }

          let addMenuIds = []
          let deleteMenuIds = []
          // 新增权限id
          getCheckedKeys.forEach(item => {
            let adds = this.defaultKyes.find(v=>{
              return v == item
            })
            if(!adds) {
              addMenuIds.push(item)
            }
          })
          // 删除权限id
          this.defaultKyes.forEach(item => {
            let del = getCheckedKeys.includes(item)
            if(!del) {
              deleteMenuIds.push(item)
            }
          })

          let addPositionIds = []
          let deletePositionIds = []
          // 新增岗位id
          this.form.positionIdList.forEach(item => {
            let adds = this.positionIdListC.find(v=>{
              return v == item
            })
            if(!adds) {
              addPositionIds.push(item)
            }
          })
          // 删除岗位id
          this.positionIdListC.forEach(item => {
            let del = this.form.positionIdList.includes(item)
            if(!del) {
              deletePositionIds.push(item)
            }
          })

          // if(!this.form.regionId) {
          //   return this.$message.error('请选择区域')
          // }
          // if(!this.form.zoneId) {
          //   return this.$message.error('请选择册本')
          // }

          // if(this.form.regionId.length==0) {
          //   return this.$message.error('请选择区域')
          // }
          // if(this.form.zoneId.length==0) {
          //   return this.$message.error('请选择册本')
          // }
          let addRegionIds = []
          let deleteRegionIds = []
          if(this.form.regionId) {
            console.log('动态regionId：'+this.form.regionId+'静态regionId：'+this.regionListC)
            // 新增区域id
            this.form.regionId.forEach(item => {
              if(item==0) return
              let adds = this.regionListC.find(v=>{
                return v == item
              })
              if(!adds) {
                addRegionIds.push(item)
              }
            })
            // 删除区域id
            this.regionListC.forEach(item => {
              let del = this.form.regionId.includes(item)
              if(!del) {
                deleteRegionIds.push(item)
              }
            })
          }

          let zoneIds = []
          let zoneIdsC = []
          let addZoneIds = []
          let deleteZoneIds = []
          if(this.form.zoneId) {
            this.form.zoneId.forEach(item => {
              zoneIds.push(item[1])
            })
            this.zoneListC.forEach(item => {
              zoneIdsC.push(item[1])
            })
            console.log('动态zoneId：'+zoneIds+'静态zoneId：'+zoneIdsC)

            // 新增册本id
            zoneIds.forEach(item => {
              let adds = zoneIdsC.find(v=>{
                return v == item
              })
              if(!adds) {
                addZoneIds.push(item)
              }
            })
            // 删除册本id
            zoneIdsC.forEach(item => {
              let del = zoneIds.includes(item)
              if(!del) {
                deleteZoneIds.push(item)
              }
            })
          }

          let form = {
            addResponsibleDepartmentIds: addResponsibleDepartmentIds,
            addMenuIds: addMenuIds,
            addPositionIds: addPositionIds,
            addRegionIds: addRegionIds?addRegionIds:null,
            addZoneIds: addZoneIds?addZoneIds:null,
            baseInfo: {
              departmentId: this.form.departmentId != this.form.departmentIdC ? this.form.departmentId : null,
              name: this.form.name != this.form.nameC ? this.form.name : null,
              phone: this.form.phone != this.form.phoneC ? this.form.phone : null,
              roleId: this.form.roleId != this.form.roleIdC ? this.form.roleId : null,
              sex: this.form.sex != this.form.sexC ? this.form.sex : null,
            },
            deleteResponsibleDepartmentIds: deleteResponsibleDepartmentIds,
            deleteMenuIds: deleteMenuIds,
            deletePositionIds: deletePositionIds,
            deleteRegionIds: deleteRegionIds?deleteRegionIds:null,
            deleteZoneIds: deleteZoneIds?deleteZoneIds:null,
            id: this.form.id,
          }
          
          this.loading = true
          this.$ajax.post("staffUpdate", form).then((res) => {
            this.loading = false
            this.$message.success('成功')
            this.$router.push('/staff')
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    loadDepartmentList() {
      this.$ajax.get("departmentTree").then((res) => {
        this.departmentTreeData = res.data;
        // 管辖部门数据
        this.dataDepTree = res.data
      });
    },
    departmentChange(id) {
      this.form.positionIdList = "";

      function filterFun(item) {
        return item.departmentId == id;
      }
      this.positionFilter = this.position.filter(filterFun);
    },
    loadPositionList() {
      this.loading = true
      this.$ajax.get("positionQueryAll").then((res) => {
        this.loading = false
        this.position = this.getTreeData(res.data)
        this.initDataForm()
      }).catch(err=>{
        this.loading = false
      })
    },
    getTreeData2(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push(item.id)
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    initDataForm() {
      this.positionFilter = this.position.filter((item) => {
        return item.departmentId == this.row.departmentId;
      });
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
              departmentId: item.departmentId,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    // 获取行政区域
    loadRegion() {
      this.$ajax.post("regionQuery").then((res) => {
        this.regionListAll = res.data;
      });
    },
    // 获取册本数据
    loadZoneList() {
      this.$ajax.post("zoneQuery",{
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.zoneList = res.data;
      });
    },
    
    regionChange(ids) {
      this.$forceUpdate()
      console.log(ids)
      if(this.isCreate) {
        console.log('新创建')
        this.form.regionId = []
        this.form.zoneId = []
        this.zoneListFilter = []
        if(ids[0]==0) {
          this.form.regionId = [0]
          return
        }else {
          this.addRegion(ids)
        }
      }else {
        if(ids.length==0){
          this.form.regionId = []
          this.form.zoneId = []
          this.zoneListFilter = []
          this.regionList = this.regionListAll
          this.isCreate = true
        }
        console.log('继续')
        this.form.regionId.forEach((v,k)=>{
          if(v==0) {
            this.form.regionId.splice(k, 1)
          }else {
            this.zoneListFilter.forEach((item,index) => {
              let del = ids.includes(item.staffRegionId)
              if(!del) {
                this.zoneListFilter.splice(index, 1)
              }
            })
          }
        })
      }
    },
    addRegion(ids) {
      this.regionList = this.regionListAll
      let obj = {}
      let arr = []
      
      ids.forEach(v=>{
        this.regionList.forEach((item,index)=>{
          if(v==item.id) {
            arr.push({
              id: item.id,
              name: item.name
            })
            this.form.regionId.push(item.id)
          }else {
            // this.form.regionId.splice(index, 1)
          }
        })
      })
      arr.forEach((v,k)=>{
        if(v==0) return
        obj = {
          value: v.id,
          label: v.name,
          children: []
        }
        this.zoneList.forEach((item,index)=>{
          if(v.id==item.regionId) {
            obj.children.push({
              value: item.id,
              label: item.name
            })
          }
        })
        this.zoneListFilter.push(obj)
      })

      // this.zoneListFilter.forEach((item,index) => {
      //   let del = ids.includes(item.value)
      //   if(!del) {
      //     console.log(item)
      //     this.zoneListFilter.splice(index, 1)
      //   }
      // })
    },
    zoneChange(e) {
      this.$forceUpdate()
      if(e.length==0) {
        this.form.regionId = []
        this.form.zoneId = []
        this.zoneListFilter = []
        this.regionList = this.regionListAll
        this.isCreate = true
      }
    },
    loadDetail() {
      this.loading = true
      this.$ajax.post('staffDetail', {
        id: this.row.id
      }).then(res => {
        res.data.phoneC = res.data.phone 
        res.data.nameC = res.data.name
        res.data.roleIdC = res.data.roleId
        res.data.sexC = res.data.sex
        res.data.departmentIdC = res.data.departmentId
        this.form = res.data

        let arr = [] 
        let positionsF = []

        let regionArr = []
        let regionInfos = []

        let zoneData = []
        let zoneObj = {}
        let showZone = []
        if(res.data.positionInfos) {
          res.data.positionInfos.forEach(v=> {
            arr.push(v.staffPositionId)
          })
          this.form.positionIdList = arr
          this.positionIdListC = arr
          
          // 回显部门列表
          res.data.positionInfos.forEach(v=> {
            positionsF.push({
              id: v.staffPositionId,
              name: v.positionName
            })
          })
          this.positionFilter = positionsF
        }

        if(res.data.regionInfos&&res.data.regionInfos.length>0) {
          // 回显行政区域
          res.data.regionInfos.forEach(v=> {
            regionArr.push(v.staffRegionId)
          })
          this.form.regionId = regionArr
          this.regionListC = regionArr

          res.data.regionInfos.forEach(v=> {
            regionInfos.push({
              id: v.staffRegionId,
              name: v.regionName
            })
          })
          this.regionList = regionInfos
        }else{
          this.regionList = this.regionListAll
          this.isCreate = true
          this.form.regionId = [0]
        }
        
        if(res.data.zoneInfos&&res.data.zoneInfos.length>0) {
          // 回显册本数据 
          res.data.zoneInfos.forEach(item=> {
            this.zoneList.forEach(zone=> {
              if(item.zoneId == zone.id) {
                item.regionId = zone.regionId
                zoneData.push(item)
              }
            })
          })
          res.data.regionInfos.forEach(v=> {
            zoneObj = {
              staffRegionId: v.staffRegionId,
              value: v.regionId,
              label: v.regionName,
              children: []
            }
            zoneData.forEach(zone=>{
              if(v.regionId == zone.regionId) {
                zoneObj.children.push({
                  value: zone.staffZoneId,
                  label: zone.zoneName
                })
                showZone.push([v.regionId,zone.staffZoneId])
              }
            })
            this.zoneListFilter.push(zoneObj)
          })
          // setTimeout(()=>{
            this.form.zoneId = showZone
            this.zoneListC = showZone
          // },50)
        }

        // 回显管辖部门
        let respArr = []
        let defaultDepKyesCopy = []
        this.depInitData = res.data.responsibleDepartmentInfos
        if(res.data.responsibleDepartmentInfos) {
          res.data.responsibleDepartmentInfos.forEach(v=>{
            respArr.push(v.departmentId)
            defaultDepKyesCopy.push(v.responsibleDepartmentId)
          })
        }
        this.defaultDepKyes = respArr
        this.defaultDepKyesCopy = defaultDepKyesCopy
        // 回显菜单配置
        this.defaultKyes = this.getTreeData2(res.data.menuTrees)
        
        this.checkStr = true
        this.checkDepStr = true
        this.$nextTick(() => {
          this.$refs.menuDepRefs.setCheckedKeys(this.defaultDepKyes)
          this.$refs.menuRefs.setCheckedKeys(this.defaultKyes)
        })
        setTimeout(()=>{
          this.checkStr = false
          this.checkDepStr = false
        },500)
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    if (this.$route.params == null) {
      this.$router.push('/staff')
      return
    } else {
      this.row = this.$route.params
    }
    this.loadRegion()
    this.loadZoneList()
    this.loadDetail()
    this.loadDepartmentList();
    this.loadPositionList();
    this.loadTree();
  }
}
</script>
<style lang="less" scoped>
.mains {
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  width: 500px;
}
.el-collapse {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 0;
}
.el-collapse-item {
  box-shadow: 0px 0px 0px rgba(211, 211, 211, 0.5);
  border: 0px solid #eee;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
}
/deep/.el-collapse-item__header {
  height: 42px;
  padding: 0 10px;
  border-bottom: 0;
  font-size: 14px;
}
/deep/.el-tree-node__label {
  font-size: 15px;
  line-height: 10px;
  letter-spacing: 2px;
}
/deep/.el-tree-node__content {
  height: 35px;
}
.el-select {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
.special {
  position: relative;
  .s-required {
    color: #F56C6C;
    position: absolute;
    top: 12px;
    left: 22px;
    font-size: 14px;
  }
}
</style>